.bnpp-numbered-list-module {
    height: fit-content;
    border: 1px solid currentColor;
    margin-bottom: var(--wp--preset--spacing--40);
    display: flex;
    font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
    
    .number {
        background-color: var(--wp--preset--color--bnpp-green-tint-15);
        border-right: 1px solid currentColor;
        padding: var(--wp--preset--spacing--40);
        font-weight: 400;
        font-size: var(--wp--preset--font-size--heading-s);
        line-height: 1.3;
        flex: 0 1 80px;
        display: grid;
        place-content: center;
    }

    .bnpp-numbered-list-module-text {
        padding: var(--wp--preset--spacing--40);
        font-size: var(--wp--preset--font-size--heading-s);
        margin: 0;
        display: flex;
        align-items: center;    
        flex: 1;
    }
    
}